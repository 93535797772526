import AccordionItem from './accordion-item';

const $giftCardMessageAccordion = document.querySelector(
    '[data-gift-card-accordion]'
);
const $giftCardMessage = document.querySelector('[data-gift-card-message]');
const $giftCardMessageCharacterRemaining = document.querySelector(
    '[data-characters-remaining]'
);

function updateRemainingCharacters() {
    $giftCardMessageCharacterRemaining.textContent = `Remaining characters: ${
        $giftCardMessage.maxLength - $giftCardMessage.value.length
    }`;
}

if (
    $giftCardMessageAccordion &&
    $giftCardMessage &&
    $giftCardMessageCharacterRemaining
) {
    $giftCardMessage.addEventListener('input', updateRemainingCharacters);
    $giftCardMessage.addEventListener('keyup', updateRemainingCharacters);

    // Open Drawer
    const accordion = new AccordionItem($giftCardMessageAccordion);
    accordion.open();
}
